import * as React from 'react';
import Layout from '../components/layout';
import { StageBlock, TextBlock } from '../components/Blocks';
import { Insert } from '../components/Insert';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { RecentProjects } from '../components/RecentProjects';
import css from '@emotion/css';

export default function Kontakt({ data }: any) {
  return (
    <Layout>
      <TextBlock
        css={css`
          @media (max-width: 800px) {
            padding-top: 4rem;
            padding-bottom: 0;
          }
        `}
      >
        <Insert>
          Dir gefällt, was du siehst?{' '}
          <em>Du möchtest dein neues Projekt mit uns planen? Dann lass uns</em>{' '}
          keine Zeit verlieren und schnacken :)
        </Insert>
      </TextBlock>

      <StageBlock
        color="transparent"
        css={css`
          @media (max-width: 800px) {
            margin-top: 0;
          }
        `}
      >
        <GatsbyImage fluid={data.file.childImageSharp.fluid} />
      </StageBlock>

      <TextBlock css={{ textAlign: 'center' }}>
        <a
          href="https://goo.gl/maps/nHxyjx5zDvws2ikb9"
          target="_blank"
          rel="noopener"
        >
          <Icon width={39} height={66}>
            <g transform="translate(-923 -1831)" fill="none" fillRule="evenodd">
              <path
                d="M942.5 1881c11-14.448 16.5-24.676 16.5-30.683 0-9.012-7.387-16.317-16.5-16.317s-16.5 7.305-16.5 16.317c0 6.007 5.5 16.235 16.5 30.683z"
                stroke="currentColor"
                strokeWidth={5}
              />
              <circle
                stroke="currentColor"
                strokeWidth={2}
                cx={943}
                cy={1851}
                r={4}
              />
              <ellipse fill="currentColor" cx={943} cy={1895} rx={11} ry={2} />
            </g>
          </Icon>
        </a>

        <div>Hier könnten sich unsere Wege kreuzen:</div>
        <a
          href="https://goo.gl/maps/nHxyjx5zDvws2ikb9"
          target="_blank"
          rel="noopener"
        >
          <List>
            <li>
              <strong>anywhere I go</strong>
            </li>
            <li>Luitpoldstraße 9 96052 Bamberg</li>
            <li>Bamberg</li>
          </List>
        </a>
        <List>
          <li>
            <strong>T: </strong>0951 / 519362 - 14 | 15 oder{' '}
          </li>
          <li>
            <strong>M:</strong>{' '}
            <a
              css={{ color: 'unset', textDecoration: 'none' }}
              href="mailto:hello@anywhereigo.com"
            >
              hello@anywhereigo.com
            </a>
          </li>
        </List>
      </TextBlock>

      <RecentProjects />
    </Layout>
  );
}

const Icon = styled.svg`
  margin-bottom: 1rem;
  color: #3c434c;
`;

const List = styled.ul`
  list-style: none;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0;
    color: #3c434c;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const query = graphql`
  query {
    file(relativePath: { eq: "kontakt.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1056) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
